import React, { Component } from 'react';
import AirshipClient from '../clients/airshipClient';
import MasterAccountServiceProxyClient from '../clients/masterAccountServiceProxyClient';
import QuantumEventServiceProxyClient from '../clients/quantumEventServiceProxyClient';
import LoadingSpinner from '../components/LoadingSpinner';
import {
    logError,
    withHttps,
    getQueryParams,
    getAccountSource
} from '../helpers';
import SsoTheme from '../components/SsoTheme';
import isURL from 'validator/es/lib/isURL';
import './UltimateController.scss';
import Input from '@payscale/design/lib/input';
import FormGroup from '@payscale/design/lib/form-group';
import Alert from '@payscale/design/lib/alert';
import ErrorView from '../components/ErrorView';
import UltimateLogo from '../assets/logos/ultimate.svg';

const viewStates = {
    loading: 'loading',
    accountSetup: 'accountSetup',
    accountSetupSuccessful: 'accountSetupSuccessful',
    error: 'error'
};

export default class UltimateController extends Component {
    constructor(props) {
        super(props);

        this.state = {
            masterAccountId: null,
            productReturnUrl: null,
            username: '',
            password: '',
            clientAccessKey: '',
            baseUrl: '',
            viewState: viewStates.loading,
            failedSave: false,
            failedSaveMessage: '',
            failedSavePermissionFailures: null
        };

        const { user, config } = this.props;
        const accessToken = user.access_token;
        this.airshipClient = new AirshipClient(
            accessToken,
            config.airshipBaseUrl
        );
        this.masProxyClient = new MasterAccountServiceProxyClient(accessToken);
        this.quantumClient = new QuantumEventServiceProxyClient(accessToken);

        this.queryParams = getQueryParams();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.handleInit();
    }

    setViewState() {
        const { queryParams } = this;
        const { masterAccountId } = queryParams;

        return this.airshipClient
            .doCredentialsExist(masterAccountId)
            .then(exists => {
                const viewState = exists
                    ? viewStates.accountSetupSuccessful
                    : viewStates.accountSetup;
                this.setState({ viewState });
            });
    }

    handleInit() {
        const { queryParams } = this;
        const { masterAccountId, productReturnUrl } = queryParams;
        if (!masterAccountId)
            throw new Error('Expected masterAccountId query parameter');
        if (!productReturnUrl)
            throw new Error('Expected productReturnUrl query parameter');
        this.setState({ masterAccountId, productReturnUrl });
        const { user } = this.props;
        const userId = user.profile.sub;
        this.masProxyClient
            .verifyUserToMasterAccount(userId, masterAccountId)
            .then(result => {
                if (result !== true)
                    throw new Error(
                        `User ${userId} is not verified to MasterAccount ${masterAccountId}`
                    );
            })
            .then(() => this.setViewState())
            .catch(err => this.handleError(err));
    }

    isFormValid() {
        return (
            this.state.username.trim() !== '' &&
            this.state.password.trim() !== '' &&
            this.state.clientAccessKey.trim() !== '' &&
            this.state.baseUrl.trim() !== '' &&
            isURL(`${this.state.baseUrl.trim()}.ultipro.com`)
        );
    }

    handleSubmit(event) {
        if (!this.isFormValid()) return;
        event.preventDefault();
        this.setState({ viewState: viewStates.loading });

        const { state } = this;
        const { user } = this.props;
        const userId = user.profile.sub;

        this.airshipClient
            .saveUltimateCredential(
                state.masterAccountId,
                state.username,
                state.password,
                state.clientAccessKey,
                withHttps(`${state.baseUrl}.ultipro.com`)
            )
            .then(() =>
                this.createAirshipAccountIfNotExists(state.masterAccountId)
            )
            .then(() =>
                this.airshipClient.importAirshipAccount(state.masterAccountId)
            )
            .then(() =>
                this.quantumClient.sendHrisConnectInitEvent(
                    'HrisConnect Ultimate Init Event',
                    'Ultimate',
                    state.masterAccountId,
                    userId,
                    user.profile.email
                )
            )
            .then(() =>
                this.setState({ viewState: viewStates.accountSetupSuccessful })
            )
            .catch(err => this.handleError(err));
    }

    createAirshipAccountIfNotExists(masterAccountId) {
        return this.airshipClient
            .doesAirshipAccountExist(masterAccountId)
            .then(exists => {
                if (exists) {
                    return Promise.resolve();
                }
                const accountSource = getAccountSource(
                    this.props.config,
                    this.state.productReturnUrl
                );
                return this.airshipClient.createAirshipAccount(
                    masterAccountId,
                    null,
                    'Ultimate',
                    accountSource,
                    'NoTarget'
                );
            });
    }

    handleFailedSaveError(err) {
        err.resultText
            .then(text => {
                var data = JSON.parse(text);
                var message = {
                    1000: 'The UltiPro URL appears to be incorrect.',
                    1001: 'Please make sure the UltiPro URL and the API Key are correct.',
                    1002: 'The UltiPro Username and UltiPro Password combination was declined.',
                    1003: 'The UltiPro URL is unreachable.',
                    1004: 'The following required permissions are not granted:'
                }[data.code];
                if (!message) {
                    throw err;
                }

                this.setState({
                    viewState: viewStates.accountSetup,
                    failedSave: true,
                    failedSaveMessage: message,
                    failedSavePermissionFailures: data.permissionFailures
                        ? data.permissionFailures
                        : null
                });
            })
            .catch(() => this.setState({ viewState: viewStates.error }));
    }

    handleError(err) {
        logError(err);
        if (err.status === 400 && err.resultText) {
            this.handleFailedSaveError(err);
            return;
        }

        this.setState({ viewState: viewStates.error });
    }

    renderAlertMessage() {
        const { failedSaveMessage } = this.state;
        const { failedSavePermissionFailures } = this.state;

        if (failedSavePermissionFailures) {
            return `${failedSaveMessage} ${failedSavePermissionFailures.join(
                ', '
            )}`;
        } else {
            return failedSaveMessage;
        }
    }

    renderAccountSetup() {
        const saveButtonEnabled = this.isFormValid();

        return (
            <div className="ultimate-controller">
                {this.state.failedSave ? (
                    <Alert message={this.renderAlertMessage()} />
                ) : (
                    <p>Let&#39;s connect directly to UltiPro.</p>
                )}
                <form
                    onSubmit={this.handleSubmit}
                    className="ultimate-controller__form"
                >
                    <div className="ultimate-controller__inputs-row">
                        <FormGroup text="UltiPro Username">
                            <Input
                                name="usernameInput"
                                disabled={this.props.loading}
                                value={this.state.username}
                                onChange={(id, value) =>
                                    this.setState({ username: value })
                                }
                            />
                        </FormGroup>
                        <FormGroup text="UltiPro Password">
                            <Input
                                name="passwordInput"
                                type="password"
                                disabled={this.props.loading}
                                value={this.state.password}
                                onChange={(id, value) =>
                                    this.setState({ password: value })
                                }
                            />
                        </FormGroup>
                    </div>
                    <div className="ultimate-controller__inputs-row">
                        <FormGroup text="API Key (5 characters)">
                            <Input
                                name="clientAccessKeyInput"
                                disabled={this.props.loading}
                                value={this.state.clientAccessKey}
                                onChange={(id, value) =>
                                    this.setState({
                                        clientAccessKey: value
                                    })
                                }
                            />
                        </FormGroup>
                        <FormGroup
                            text="UltiPro URL"
                            className="ultimate-controller__url-form-group"
                        >
                            <Input
                                name="baseUrlInput"
                                className="ultimate-controller__url"
                                disabled={this.props.loading}
                                value={this.state.baseUrl}
                                onChange={(id, value) =>
                                    this.setState({ baseUrl: value })
                                }
                            />
                            <span className="ultimate-controller__url-suffix">
                                .ultipro.com
                            </span>
                        </FormGroup>
                    </div>
                    <input
                        type="submit"
                        className="ultimate-controller__submit-button pxl-btn pxl-btn--primary"
                        disabled={!saveButtonEnabled}
                        value="Connect"
                    />
                </form>
            </div>
        );
    }

    renderAccountSetupSuccessful() {
        const { productReturnUrl } = this.state;
        return (
            <div className="ultimate-controller">
                <p>
                    You&#39;ve connected to UltiPro! Email your PayScale API
                    contact to finalize your connection.
                </p>
                <a
                    className="pxl-btn pxl-btn--primary ultimate-controller__back-button"
                    href={productReturnUrl}
                >
                    Back to Insight Lab
                </a>
            </div>
        );
    }

    render() {
        const { viewState, productReturnUrl } = this.state;
        let content;
        switch (viewState) {
            case viewStates.loading:
                content = <LoadingSpinner />;
                break;
            case viewStates.accountSetup:
                content = this.renderAccountSetup();
                break;
            case viewStates.accountSetupSuccessful:
                content = this.renderAccountSetupSuccessful();
                break;
            case viewStates.error:
            default:
                content = <ErrorView productReturnUrl={productReturnUrl} />;
        }

        return (
            <SsoTheme
                logo={UltimateLogo}
                logoWidth={109}
                logoHeight={56}
                logoAlt="Ultimate logo"
            >
                {content}
            </SsoTheme>
        );
    }
}
