// Note: special prototype magic is required to extend the Error class when
// using Babel. Otherwise the `instanceof` operator does not work
//
// See https://stackoverflow.com/a/43595019

// eslint-disable-next-line import/prefer-default-export
export function HttpError(status, statusText, resultText) {
    const message = `Invalid response from server: ${status} ${statusText}`;
    const instance = new Error(message);
    instance.status = status;
    instance.statusText = statusText;
    instance.resultText = resultText;
    Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    if (Error.captureStackTrace) {
        Error.captureStackTrace(this, this.constructor);
    }
    return instance;
}

HttpError.prototype = Object.create(Error.prototype, {
    constructor: {
        value: Error,
        enumerable: false,
        writable: true,
        configurable: true
    }
});

if (Object.setPrototypeOf) {
    Object.setPrototypeOf(HttpError, Error);
} else {
    HttpError.__proto__ = Error; // eslint-disable-line no-proto
}
