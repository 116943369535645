import React from 'react';
import PropTypes from 'prop-types';
import './SsoTheme.scss';
import PayScaleLogo from '../assets/logos/payscale.svg';

const SsoTheme = ({ children, logo, logoWidth, logoHeight, logoAlt }) => {
    const imageProps = {
        ...(logoWidth ? { width: logoWidth } : {}),
        ...(logoHeight ? { height: logoHeight } : {}),
    };

    return (
        <div className="sso-theme">
            <div className="sso-theme__logos">
                <img
                    className="sso-theme__payscale-logo"
                    src={PayScaleLogo}
                    alt="PayScale logo"
                />
                {logo ? (
                    <>
                        <div className="sso-theme__logo-spacer" />
                        <img src={logo} {...imageProps} alt={logoAlt} />
                    </>
                ) : null}
            </div>
            <div className="sso-theme__wrapper">{children}</div>
        </div>
    );
};

SsoTheme.propTypes = {
    children: PropTypes.node.isRequired,
    logo: PropTypes.any,
    logoWidth: PropTypes.number,
    logoHeight: PropTypes.number,
    logoAlt: PropTypes.string
};

export default SsoTheme;
