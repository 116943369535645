import AuthorizedClient from './authorizedClient';

// Client for the backend proxy to MasterAccountService

export default class MasterAccountServiceProxyClient extends AuthorizedClient {
    verifyUserToMasterAccount(userId, masterAccountId) {
        return this.handleRequest(
            'GET',
            `/proxy/mas/account/${masterAccountId}/users/${userId}`
        )
            .then(res => res.json())
            .then(body => body.verified === true);
    }
}
