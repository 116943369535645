import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AdpController from './AdpController';
import getQueryParams from '../helpers/getQueryParams';

export default class AdpSession extends Component {
    constructor(props) {
        super(props);

        this.state = {
            session: null
        };
    }

    componentDidMount() {
        this.initializeSessionFromQueryString();
    }

    initializeSessionFromQueryString() {
        const queryParams = getQueryParams();
        const { masterAccountId, productReturnUrl } = queryParams;
        if (!masterAccountId)
            throw new Error('Expected masterAccountId query parameter');
        if (!productReturnUrl)
            throw new Error('Expected productReturnUrl query parameter');
        const session = { masterAccountId, productReturnUrl };
        this.setState({ session });
    }

    render() {
        const { session } = this.state;
        if (!session) return null;

        const { masterAccountId, productReturnUrl } = session;

        const { config, user } = this.props;

        return (
            <AdpController
                masterAccountId={masterAccountId}
                productReturnUrl={productReturnUrl}
                config={config}
                user={user}
            />
        );
    }
}

AdpSession.propTypes = {
    config: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
};
