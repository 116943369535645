import queryString from 'query-string';

// Why separate this out into its own module? Makes mocking easier
// since mocking the `window` global object is difficult, while
// mocking this module is easy

const getQueryParams = () => {
    const { location } = window;
    const params = queryString.parse(location.search);
    return params;
};

export default getQueryParams;
