import React from 'react';
import './AwaitingConsentView.scss';

const AwaitingConsentView = () => (
    <div className="awaiting-consent-view">
        <p>You&#39;re almost done!</p>
        <p>
            Finally, we need to obtain consent to access your ADP data. If
            you&#39;ve already given consent, please check back soon.
        </p>
        <div className="awaiting-consent-view__consent-button-wrapper">
            <a
                href="https://adpapps.adp.com/consent-manager"
                target="_blank"
                rel="noopener noreferrer"
                className="pxl-btn pxl-btn--primary"
            >
                Give consent via ADP
            </a>
        </div>
    </div>
);

export default AwaitingConsentView;
