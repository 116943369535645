import AuthorizedClient from './authorizedClient';

export default class AirshipClient extends AuthorizedClient {
    constructor(accessToken, baseUrl) {
        super(accessToken);
        if (!baseUrl) throw new Error('baseUrl is null or undefined');
        // Ensure baseUrl has a trailing slash
        const hasTrailingSlash = baseUrl[baseUrl.length - 1] === '/';
        this.baseUrl = hasTrailingSlash ? baseUrl : `${baseUrl}/`;
    }

    getAirshipAccount(masterAccountId) {
        return new Promise((resolve, reject) =>
            this.handleRequest(
                'GET',
                `${this.baseUrl}api/v1/account/${masterAccountId}`
            )
                .then(res => res.json())
                .then(account => resolve(account))
                .catch(err => {
                    if (err.status === 404) resolve(null);
                    else reject(err);
                })
        );
    }

    createAirshipAccount(
        masterAccountId,
        name,
        accountType,
        accountSource,
        accountTarget,
        metadata = null
    ) {
        const payload = {
            masterAccountId,
            name,
            accountType,
            accountSource,
            accountTarget,
            isActive: true,
            metadata
        };
        return this.handleRequest(
            'POST',
            `${this.baseUrl}api/v1/account`,
            payload
        );
    }

    importAirshipAccount(masterAccountId) {
        return this.handleRequest('POST', `${this.baseUrl}api/v1/import`, {
            masterAccountId
        });
    }

    processWorkdayCredentials(masterAccountId, username, password, reportUrl) {
        const path = `${this.baseUrl}api/v1/Credentials/${masterAccountId}/workday`;
        const payload = {
            reportUrl,
            username,
            password
        };
        return this.handleRequest('POST', path, payload);
    }

    doesAirshipAccountExist(masterAccountId) {
        return this.getAirshipAccount(masterAccountId).then(
            account => account !== null
        );
    }

    doCredentialsExist(masterAccountId) {
        const path = `${this.baseUrl}api/v1/credentials/${masterAccountId}`;
        return new Promise((resolve, reject) => {
            this.handleRequest('GET', path)
                .then(() => {
                    resolve(true);
                })
                .catch(err => {
                    if (err.status === 404) resolve(false);
                    else reject(err);
                });
        });
    }

    getBambooOidcAuthorizationUrl(subdomain, redirectUri, state) {
        const path = `${this.baseUrl}api/v1/bamboo-oidc/authorizationurl`;
        const query = `?subdomain=${subdomain}&redirectUri=${redirectUri}&state=${state}`;
        return this.handleRequest('GET', path + query)
            .then(res => res.json())
            .then(res => res.authorizationUrl);
    }

    processAuthCode(subdomain, redirectUri, authCode, masterAccountId) {
        const path = `${this.baseUrl}api/v1/bamboo-oidc/processauthcode`;
        const payload = {
            subdomain,
            redirectUri,
            authCode,
            masterAccountId
        };
        return this.handleRequest('POST', path, payload);
    }

    getAdpSubscriptionCode(masterAccountId) {
        const path = `${this.baseUrl}api/v1/adp/subscription/code/${masterAccountId}`;
        return this.handleRequest('POST', path)
            .then(res => res.json())
            .then(res => res.subscriptionCode);
    }

    saveUltimateCredential(
        masterAccountId,
        username,
        password,
        clientAccessKey,
        baseUrl
    ) {
        const path = `${this.baseUrl}api/v1/ultimate-credentials/save`;
        const payload = {
            username,
            password,
            clientAccessKey,
            baseUrl,
            masterAccountId
        };
        return this.handleRequest('POST', path, payload);
    }
}
