import React from 'react';
import PropTypes from 'prop-types';
import './ConnectionReadyView.scss';

const ConnectionReadyView = ({ productReturnUrl }) => (
    <div className="connection-ready-view">
        <p>
            You&#39;ve connected to ADP! Email your PayScale API contact to
            finalize your connection.
        </p>
        <a
            className="pxl-btn pxl-btn--primary connection-ready-view__back-button"
            href={productReturnUrl}
        >
            Back to Insight Lab
        </a>
    </div>
);

ConnectionReadyView.propTypes = {
    productReturnUrl: PropTypes.string.isRequired
};

export default ConnectionReadyView;
