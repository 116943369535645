const getAccountSource = (appConfig, productReturnUrl) => {
    const labHostNames = appConfig.labHostNames.split(',');
    const mpHostNames = appConfig.marketpayHostNames.split(',');
    const hostName = new URL(productReturnUrl).hostname;
    if (labHostNames.includes(hostName)) {
        return 'InsightLab';
    } else if (mpHostNames.includes(hostName)) {
        return 'Marketpay';
    }
    return 'Undefined';
};

export default getAccountSource;
