import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import viewStates from '../viewStates';
import AwaitingConsentView from './AwaitingConsentView';
import './AwaitingSubscriptionView.scss';

const SubscriptionCodeSection = ({ subscriptionCode }) => (
    <p>
        <strong>{subscriptionCode}</strong>
        <CopyToClipboard text={subscriptionCode}>
            <button className="pxl-btn pxl-btn--xs awaiting-subscription-view__copy-to-clipboard-button">
                Copy to clipboard
            </button>
        </CopyToClipboard>
    </p>
);

const AwaitingCodeSection = ({ message }) => (
    <p>
        <strong>{message}</strong>
    </p>
);

export default class AwaitingSubscriptionView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { subscriptionCode: null };
    }

    componentDidMount() {
        this.fetchSubscriptionCode();
        this.watchforSubscriberOrgOid();
    }

    fetchSubscriptionCode(retryCount = 5) {
        const { airshipClient, masterAccountId } = this.props;
        airshipClient
            .getAdpSubscriptionCode(masterAccountId)
            .then(subscriptionCode => this.setState({ subscriptionCode }))
            .catch(err => {
                if (retryCount > 0) {
                    window.setTimeout(
                        () => this.fetchSubscriptionCode(retryCount - 1),
                        1000
                    );
                }
            });
    }

    watchforSubscriberOrgOid() {
        const { airshipClient, masterAccountId } = this.props;
        window.setTimeout(() => {
            let viewState = viewStates.awaitingConsent;
            airshipClient.getAirshipAccount(masterAccountId).then(account => {
                if (account && account.metadata.subscriberOrgOId) {
                    this.setState({ viewState });
                } else {
                    this.watchforSubscriberOrgOid();
                }
            });
        }, 5000);
    }

    render() {
        const { subscriptionCode, viewState } = this.state;
        switch (viewState) {
            case viewStates.awaitingConsent:
                return <AwaitingConsentView />;
            case viewStates.awaitingSubscription:
            default:
                return (
                    <div className="awaiting-subscription-view">
                        <p>Let's connect directly to ADP.</p>
                        <p>
                            First, you must subscribe to the PayScale app in the
                            ADP Marketplace.
                        </p>
                        <p>
                            When completing the subscription, you will be
                            prompted to enter a subscription code. Here is your
                            subscription code:
                        </p>
                        {subscriptionCode ? (
                            <SubscriptionCodeSection
                                subscriptionCode={subscriptionCode}
                            />
                        ) : (
                            <AwaitingCodeSection message="Generating subscription code.  Please wait." />
                        )}
                        <p>
                            Please, keep this page open. It will refresh
                            automatically when you have completed your
                            subscription.
                        </p>
                        <div className="awaiting-subscription-view__marketplace-button-wrapper">
                            <a
                                href={this.props.adpMarketplaceUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="pxl-btn pxl-btn--primary awaiting-subscription-view__marketplace-button"
                            >
                                Go to ADP Marketplace
                            </a>
                        </div>
                    </div>
                );
        }
    }
}

AwaitingSubscriptionView.propTypes = {
    adpMarketplaceUrl: PropTypes.string.isRequired,
    masterAccountId: PropTypes.string.isRequired,
    airshipClient: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
};
