import AuthorizedClient from './authorizedClient';
import { logError } from '../helpers/logger';

export default class QuantumEventServiceProxClient extends AuthorizedClient {
    sendHrisConnectInitEvent(
        event,
        hrisSystem,
        masterAccountId,
        userId,
        email
    ) {
        const info = {
            hrisSystem,
            userId,
            email
        };
        const eventInfo = JSON.stringify(info);
        const eventType = event;
        const payload = {
            eventType,
            eventInfo
        };

        return new Promise(resolve => {
            this.handleRequest(
                'POST',
                `/proxy/qes/account/${masterAccountId}/InitEvent`,
                payload
            )
                .then(() => {
                    resolve(true);
                })
                .catch(err => {
                    logError('Could not log event to quantum' + err);
                    resolve(true);
                });
        });
    }
}
