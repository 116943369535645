import React from 'react';
import './LoadingSpinner.scss';

const LoadingSpinner = () => {
    return (
        <div className="loading-spinner">
            <span className="icon-spin icon-animate-spin loading-spinner__icon" />
        </div>
    );
};

export default LoadingSpinner;
