import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AirshipClient from '../clients/airshipClient';
import MasterAccountServiceProxyClient from '../clients/masterAccountServiceProxyClient';
import QuantumEventServiceProxyClient from '../clients/quantumEventServiceProxyClient';
import { logError } from '../helpers/logger';
import {
    AwaitingSubscriptionView,
    AwaitingConsentView,
    ConnectionReadyView
} from './views';
import viewStates from './viewStates';
import SsoTheme from '../components/SsoTheme';
import { employeeIdFieldValues } from './employeeIdFieldMappings';
import getAccountSource from '../helpers/getAccountSource';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorView from '../components/ErrorView';
import AdpLogo from '../assets/logos/adp.png';

export default class AdpController extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewState: viewStates.loading
        };

        const { user, config } = this.props;
        const accessToken = user.access_token;

        this.masProxyClient = new MasterAccountServiceProxyClient(accessToken);
        this.airshipClient = new AirshipClient(
            accessToken,
            config.airshipBaseUrl
        );
        this.quantumClient = new QuantumEventServiceProxyClient(accessToken);
        this.handleError = this.handleError.bind(this);
        this.handleInitializeAccount = this.handleInitializeAccount.bind(this);
    }

    componentDidMount() {
        this.verifyUserToMasterAccount()
            .then(() => this.setViewState())
            .catch(this.handleError);
    }

    setViewState() {
        const { masterAccountId } = this.props;

        const getAirshipAccount = this.airshipClient.getAirshipAccount(
            masterAccountId
        );
        const doCredentialsExist = this.airshipClient.doCredentialsExist(
            masterAccountId
        );
        Promise.all([getAirshipAccount, doCredentialsExist])
            .then(results => {
                const airshipAccount = results[0];
                const credentialsExist = results[1];

                let viewState;
                if (airshipAccount) {
                    const { subscriberOrgOId } = airshipAccount.metadata;
                    if (!subscriberOrgOId) {
                        viewState = viewStates.awaitingSubscription;
                    } else {
                        if (credentialsExist === true) {
                            this.sendEventToQuantum();
                            viewState = viewStates.connectionReady;
                        } else {
                            viewState = viewStates.awaitingConsent;
                        }
                    }
                } else {
                    this.handleInitializeAccount(
                        employeeIdFieldValues.workerId
                    );
                    viewState = viewStates.awaitingSubscription;
                }

                this.setState({ viewState });
            })
            .catch(this.handleError);
    }

    verifyUserToMasterAccount() {
        const { user, masterAccountId } = this.props;
        // "sub" is used as user id
        const userId = user.profile.sub;
        return this.masProxyClient
            .verifyUserToMasterAccount(userId, masterAccountId)
            .then(verified => {
                if (!verified) {
                    throw new Error(
                        `User ${userId} is not verified to MasterAccount ${masterAccountId}`
                    );
                }
            });
    }

    sendEventToQuantum() {
        const { user, masterAccountId } = this.props;
        const userId = user.profile.sub;

        this.quantumClient.sendHrisConnectInitEvent(
            'HrisConnect ADP Init Event',
            'ADP',
            masterAccountId,
            userId,
            user.profile.email
        );
    }

    handleError(err) {
        logError(err);
        this.setState({ viewState: viewStates.error });
    }

    handleInitializeAccount(employeeIdFieldValue) {
        const { masterAccountId, productReturnUrl, config } = this.props;

        const accountSource = getAccountSource(config, productReturnUrl);
        this.airshipClient
            .createAirshipAccount(
                masterAccountId,
                null,
                'Adp',
                accountSource,
                'NoTarget',
                { employeeIdFieldName: employeeIdFieldValue }
            )
            .then(() =>
                this.setState({
                    viewState: viewStates.awaitingSubscription
                })
            )
            .catch(error => {
                this.handleError(error);
            });
    }

    render() {
        const { viewState } = this.state;

        let content;
        switch (viewState) {
            case viewStates.loading:
                content = <LoadingSpinner />;
                break;
            case viewStates.awaitingSubscription:
                content = (
                    <AwaitingSubscriptionView
                        adpMarketplaceUrl={this.props.config.adpMarketplaceUrl}
                        masterAccountId={this.props.masterAccountId}
                        airshipClient={this.airshipClient}
                    />
                );
                break;
            case viewStates.awaitingConsent:
                content = <AwaitingConsentView />;
                break;
            case viewStates.connectionReady:
                content = (
                    <ConnectionReadyView
                        productReturnUrl={this.props.productReturnUrl}
                    />
                );
                break;
            case viewStates.error:
            default:
                content = (
                    <ErrorView productReturnUrl={this.props.productReturnUrl} />
                );
        }

        return (
            <SsoTheme
                logo={AdpLogo}
                logoWidth={110}
                logoHeight={50}
                logoAlt="ADP Logo"
            >
                {content}
            </SsoTheme>
        );
    }
}

AdpController.propTypes = {
    masterAccountId: PropTypes.string.isRequired,
    productReturnUrl: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
};
