// eslint-disable-next-line no-underscore-dangle, no-multi-assign
const log = (window._log = window._log || []);

const logMessage = (level, message) => {
    log.push(`${new Date().toISOString()} :: ${level} :: ${message}`);
};

export const logError = message => {
    logMessage('error', message);
};

export const logInformation = message => {
    logMessage('info', message);
};
