import { HttpError } from '../helpers/errors';

export default class AuthorizedClient {
    constructor(accessToken) {
        if (!accessToken) throw new Error('accessToken is null or undefined');
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', `Bearer ${accessToken}`);
        this.headers = headers;
    }

    handleRequest(method, url, body) {
        const bodyJson = body ? JSON.stringify(body) : null;
        return fetch(url, {
            body: bodyJson,
            headers: this.headers,
            method
        }).then(res => {
            if (res.ok) return res;
            throw new HttpError(res.status, res.statusText, res.text());
        });
    }
}
