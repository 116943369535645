import React, { Component } from 'react';
import uuid from 'uuid/v4';
import AirshipClient from '../clients/airshipClient';
import QuantumEventServiceProxyClient from '../clients/quantumEventServiceProxyClient';
import MasterAccountServiceProxyClient from '../clients/masterAccountServiceProxyClient';
import { logError } from '../helpers/logger';
import LoadingSpinner from '../components/LoadingSpinner';
import getQueryParams from '../helpers/getQueryParams';
import SsoTheme from '../components/SsoTheme';
import getAccountSource from '../helpers/getAccountSource';
import ErrorView from '../components/ErrorView';
import Input from '@payscale/design/lib/input';
import FormGroup from '@payscale/design/lib/form-group';
import './BambooHrController.scss';
import isURL from 'validator/es/lib/isURL';
import BambooHRLogo from '../assets/logos/bamboohr.svg';

const { sessionStorage, location } = window;

const redirectUri = `${location.origin}${location.pathname}`.toLowerCase();

const viewStates = {
    loading: 'loading',
    accountSetup: 'accountSetup',
    accountSetupSuccessful: 'accountSetupSuccessful',
    error: 'error'
};

export default class BambooHrController extends Component {
    constructor(props) {
        super(props);

        this.state = {
            masterAccountId: null,
            productReturnUrl: null,
            subdomain: '',
            viewState: viewStates.loading
        };

        const { user, config } = this.props;
        const accessToken = user.access_token;
        this.airshipClient = new AirshipClient(
            accessToken,
            config.airshipBaseUrl
        );
        this.masProxyClient = new MasterAccountServiceProxyClient(accessToken);
        this.quantumClient = new QuantumEventServiceProxyClient(accessToken);

        this.queryParams = getQueryParams();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.handleInit();
    }

    setViewState() {
        const { queryParams } = this;
        const { masterAccountId } = queryParams;
        return this.airshipClient
            .doCredentialsExist(masterAccountId)
            .then(exists => {
                // If the credentials already exist in Airship, skip to success view
                const viewState = exists
                    ? viewStates.accountSetupSuccessful
                    : viewStates.accountSetup;
                this.setState({ viewState });
            });
    }

    handleInit() {
        const { queryParams } = this;
        const isBambooCallback = queryParams.state && queryParams.code;
        if (isBambooCallback) {
            this.handleBambooCallback();
            return;
        }

        const { masterAccountId, productReturnUrl } = queryParams;
        if (!masterAccountId)
            throw new Error('Expected masterAccountId query parameter');
        if (!productReturnUrl)
            throw new Error('Expected productReturnUrl query parameter');
        this.setState({ masterAccountId, productReturnUrl });

        const { user } = this.props;
        // "sub" is used as user id
        const userId = user.profile.sub;

        this.masProxyClient
            .verifyUserToMasterAccount(userId, masterAccountId)
            .then(result => {
                if (result !== true)
                    throw new Error(
                        `User ${userId} is not verified to MasterAccount ${masterAccountId}`
                    );
            })
            .then(() => this.setViewState())
            .catch(err => this.handleError(err));
    }

    handleBambooCallback() {
        const sessionStateKey = this.queryParams.state;
        const sessionStateJson = sessionStorage.getItem(sessionStateKey);

        if (!sessionStateJson) {
            throw new Error(`Session not found under key ${sessionStateKey}`);
        }

        // Re-hydrate state based on stored sessionState
        const sessionState = JSON.parse(sessionStateJson);
        this.setState({
            masterAccountId: sessionState.masterAccountId,
            productReturnUrl: sessionState.productReturnUrl,
            subdomain: sessionState.subdomain
        });
        // Don't replay auth codes that have already been used; assume the
        // account was created
        if (sessionState.authCodeProcessed) {
            this.setState({ viewState: viewStates.accountSetupSuccessful });
            return;
        }

        const { user } = this.props;
        const authCode = this.queryParams.code;
        if (!authCode) throw new Error('Expected auth code in query string!');
        this.airshipClient
            .processAuthCode(
                sessionState.subdomain,
                redirectUri,
                authCode,
                sessionState.masterAccountId
            )
            .then(() => {
                sessionState.authCodeProcessed = true;
                sessionStorage.setItem(
                    sessionStateKey,
                    JSON.stringify(sessionState)
                );
            })
            .then(() =>
                this.createAirshipAccountIfNotExists(
                    sessionState.masterAccountId
                )
            )
            .then(() =>
                this.airshipClient.importAirshipAccount(
                    sessionState.masterAccountId
                )
            )
            .then(() =>
                this.quantumClient.sendHrisConnectInitEvent(
                    'HrisConnect Bamboo Init Event',
                    'Bamboo',
                    sessionState.masterAccountId,
                    sessionState.userId,
                    user.profile.email
                )
            )
            .then(() =>
                this.setState({ viewState: viewStates.accountSetupSuccessful })
            )
            .catch(err => this.handleError(err));
    }

    createAirshipAccountIfNotExists(masterAccountId) {
        return this.airshipClient
            .doesAirshipAccountExist(masterAccountId)
            .then(exists => {
                if (exists) {
                    return Promise.resolve();
                }
                const accountSource = getAccountSource(
                    this.props.config,
                    this.state.productReturnUrl
                );
                return this.airshipClient.createAirshipAccount(
                    masterAccountId,
                    null,
                    'Bamboo',
                    accountSource,
                    'NoTarget'
                );
            });
    }

    isFormValid() {
        return (
            this.state.subdomain.trim() !== '' &&
            isURL(`${this.state.subdomain.trim()}.bamboohr.com`)
        );
    }

    handleSubmit(event) {
        if (!this.isFormValid()) return;
        event.preventDefault();
        this.setState({ viewState: viewStates.loading });
        const { state } = this;
        const sessionState = {
            masterAccountId: state.masterAccountId,
            subdomain: state.subdomain,
            productReturnUrl: state.productReturnUrl
        };
        const sessionStateKey = uuid().replace(/-/g, '');
        sessionStorage.setItem(sessionStateKey, JSON.stringify(sessionState));
        this.airshipClient
            .getBambooOidcAuthorizationUrl(
                state.subdomain,
                redirectUri,
                sessionStateKey
            )
            .then(url => {
                window.location = url;
            })
            .catch(err => this.handleError(err));
    }

    handleError(err) {
        logError(err);
        this.setState({ viewState: viewStates.error });
    }

    renderAccountSetup() {
        const saveButtonEnabled = this.isFormValid();

        return (
            <div className="bamboo-hr-controller">
                <p>Let&#39;s connect directly to BambooHR.</p>
                <form
                    onSubmit={this.handleSubmit}
                    className="bamboo-hr-controller__form"
                >
                    <FormGroup
                        text="BambooHR URL"
                        className="bamboo-hr-controller__url-form-group"
                    >
                        <Input
                            name="baseUrlInput"
                            className="bamboo-hr-controller__url"
                            disabled={this.props.loading}
                            value={this.state.subdomain}
                            onChange={(id, value) =>
                                this.setState({ subdomain: value })
                            }
                        />
                        <span className="bamboo-hr-controller__url-suffix">
                            .bamboohr.com
                        </span>
                    </FormGroup>
                    <input
                        type="submit"
                        className="bamboo-hr-controller__submit-button pxl-btn pxl-btn--primary"
                        disabled={!saveButtonEnabled}
                        value="Connect"
                    />
                </form>
            </div>
        );
    }

    renderAccountSetupSuccessful() {
        return (
            <div className="bamboo-hr-controller">
                <p>
                    You&#39;ve connected to BambooHR! Email your PayScale API
                    contact to finalize your connection.
                </p>
                <a
                    className="pxl-btn pxl-btn--primary bamboo-hr-controller__back-button"
                    href={this.state.productReturnUrl}
                >
                    Back to Insight Lab
                </a>
            </div>
        );
    }

    render() {
        const { viewState, productReturnUrl } = this.state;
        let content;
        switch (viewState) {
            case viewStates.loading:
                content = <LoadingSpinner />;
                break;
            case viewStates.accountSetup:
                content = this.renderAccountSetup();
                break;
            case viewStates.accountSetupSuccessful:
                content = this.renderAccountSetupSuccessful();
                break;
            case viewStates.error:
            default:
                content = <ErrorView productReturnUrl={productReturnUrl} />;
        }

        return (
            <SsoTheme
                logo={BambooHRLogo}
                logoWidth={219}
                logoHeight={32}
                logoAlt="BambooHR logo"
            >
                {content}
            </SsoTheme>
        );
    }
}
