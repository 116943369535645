const employeeIdFieldValues = {
    positionId: 'PositionId',
    workerId: 'WorkerId',
    payrollFileNumber: 'PayrollFileNumber'
};

const employeeIdFields = {
    workforceNowPositionId: 'workforceNowPositionId',
    workforceNowWorkerId: 'workforceNowWorkerId',
    workforceNowPayrollFileNumber: 'workforceNowPayrollFileNumber',
    vantageWorkerId: 'vantageWorkerId',
    vantagePayrollFileNumber: 'vantagePayrollFileNumber'
};

const employeeIdFieldMappings = {
    [employeeIdFields.workforceNowPositionId]: {
        value: employeeIdFieldValues.positionId,
        label: 'Position ID (ex. XYZ000123)'
    },
    [employeeIdFields.workforceNowWorkerId]: {
        value: employeeIdFieldValues.workerId,
        label: 'Associate ID (ex. 0XYZ12YZ3)'
    },
    [employeeIdFields.workforceNowPayrollFileNumber]: {
        value: employeeIdFieldValues.payrollFileNumber,
        label: 'File Number (ex. 000123)'
    },
    [employeeIdFields.vantageWorkerId]: {
        value: employeeIdFieldValues.workerId,
        label: 'Employee ID'
    },
    [employeeIdFields.vantagePayrollFileNumber]: {
        value: employeeIdFieldValues.payrollFileNumber,
        label: 'File Number'
    }
};

export { employeeIdFields, employeeIdFieldValues, employeeIdFieldMappings };
