import { UserManager } from 'oidc-client';

const { sessionStorage, location } = window;
const nextUriSessionKey = 'sso-next';

export default class PayScaleSsoHandler {
    constructor(opts) {
        const { identityServerBaseUrl } = opts;
        if (!identityServerBaseUrl)
            throw new Error('identityServerBaseUrl is null or undefined');

        const settings = {
            authority: identityServerBaseUrl,
            client_id: 'hrisconnect',
            redirect_uri: `${location.origin}/login`,
            response_type: 'id_token token',
            scope: 'openid email airship account quantum',
            filterProtocolClaims: true,
            loadUserInfo: true
        };

        this.mgr = new UserManager(settings);
    }

    handleSsoCallback() {
        this.mgr.signinRedirectCallback().then(user => {
            if (!user) throw new Error('No user after processing callback!');
            const next = sessionStorage.getItem(nextUriSessionKey);
            if (next) {
                sessionStorage.removeItem(nextUriSessionKey);
                location.href = next;
            } else {
                // redirect to root and remove hash
                location.href = `${location.origin}/`;
            }
        });
    }

    signinSso() {
        return new Promise((resolve, reject) => {
            this.mgr
                .getUser()
                .then(user => {
                    if (!user || user.expired) {
                        sessionStorage.setItem(
                            nextUriSessionKey,
                            location.href
                        );
                        this.mgr.signinRedirect();
                    } else {
                        resolve(user);
                    }
                })
                .catch(err => reject(err));
        });
    }
}
