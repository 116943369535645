import React from 'react';
import './ErrorView.scss';

const ErrorView = ({ productReturnUrl }) => (
    <div className="error-view">
        <p>Oh no! An error has occurred.</p>
        <a
            className="pxl-btn pxl-btn--primary error-view__back-button"
            href={productReturnUrl}
        >
            Go Back
        </a>
    </div>
);

export default ErrorView;
