import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import BambooHrController from './bambooHr/BambooHrController';
import UltimateController from './ultimate/UltimateController';
import AdpSession from './adp/AdpSession';

const App = ({ config, user }) => (
    <Router>
        <Switch>
            <Route
                exact
                path="/adp"
                render={() => <AdpSession config={config} user={user} />}
            />
            <Route
                exact
                path="/bamboohr"
                render={() => (
                    <BambooHrController config={config} user={user} />
                )}
            />
            <Route
                exact
                path="/ultimate"
                render={() => (
                    <UltimateController config={config} user={user} />
                )}
            />
        </Switch>
    </Router>
);

export default App;
