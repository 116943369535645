import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import PayScaleSsoHandler from './clients/payScaleSsoHandler';
import appConfig from './helpers/appConfig';

const handler = new PayScaleSsoHandler({
    identityServerBaseUrl: appConfig.identityServerBaseUrl
});

function renderApp(user) {
    ReactDOM.render(
        <App user={user} config={appConfig} />,
        document.getElementById('root')
    );
}

function handleSso() {
    return new Promise((resolve, reject) => {
        if (window.location.pathname === '/login') {
            handler.handleSsoCallback();
        } else {
            handler
                .signinSso()
                .then(user => resolve(user))
                .catch(err => reject(err));
        }
    });
}

handleSso().then(user => renderApp(user));
